import {Card, Checkbox, Col, Dropdown, Form, FormInstance, Input, Modal, Row, Select, Switch} from 'antd';
import React, {RefObject} from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import {connect} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import {
    ApartmentOutlined,
    BarsOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    FilterOutlined,
    SaveOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group";
import EmployeeDisplayList from "./display/EmployeeDisplayList";
import EmployeesService from "../../../../../../model/service/company/EmployeesService";
import IEmployee from "../../../../../../model/interface/company/IEmployee";
import Utils from "../../../../../../utils";
import CompanyStructureService from "../../../../../../model/service/company/CompanyStructureService";
import FormFieldCompanyStructure from "./FormFieldCompanyStructure";
import {ISetupState} from "../../../../../../redux/reducers/Setup";
import IUser from "../../../../../../model/interface/security/IUser";
import ILabelValue from "../../../../../../model/interface/util/ILabelValue";
import IEmployeePickerFilter from "../../../../../../model/interface/company/IEmployeePickerFilter";
import Button from 'components/shared/button/Button';
import {IAppState} from "../../../../../../redux/store";
import {update} from "../../../../../../redux/actions/Setup";
import EmployeePickerFiltersService from "../../../../../../model/service/company/EmployeePickerFiltersService";
import ResourcePicker from "../../../../../shared/pickers/ResourcePicker";
import JobsService from "../../../../../../model/service/company/JobsService";
import AntdTableOptionsToRestOptionsService
    from "../../../../../../model/service/api/AntdTableOptionsToRestOptionsService";
import DragSortList from "../../../../../shared/list/DragsortList";
import arrayMove from "array-move";
import UsersService from "../../../../../../model/service/security/UsersService";
import ScrollContainer from 'components/shared/scrollContainer/ScrollContainer';
import ObligationsService from "../../../../../../model/service/company/workload/ObligationsService";
import LocaleText from "../../../../settings/dictionary/LocaleText";

interface IProps {
    value?: IEmployee | string | (string | IEmployee)[],
    onChange?: (key?: string | string[]) => void
    options: IFieldOptions
    findServiceByClassName: (name: string) => IRepositoryService
    user: IUser
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => ISetupState
    customFilters?: {
        [property: string]: any
    }
}

interface IState {
    data: {
        [x: string]: string
    },
    loading: boolean
    options: IFieldOptions
    visible: boolean
    filterVisible: boolean
    order: "ASC" | "DESC"
    displayType: 'list' | 'tree'
    search: string
    selected: ILabelValue<string>[]
    units?: ILabelValue<string>[]
    filteredUnit?: string
    filter?: IEmployeePickerFilter
    deleting: string[]
    saving?: boolean
    selectOptions?: ILabelValue<string>[]
    customFilters: {
        [property: string]: any
    }
}


class FormFieldEmployee extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()
    selectRef: RefObject<HTMLDivElement> = React.createRef()
    dropdownRef: RefObject<HTMLDivElement> = React.createRef()

    CUSTOM_FILTERS: {
        [name: string]: {
            label: string,
            defaultValue?: any,
            code: string
        }
    } = {
        active: {
            label: 'Aktivní', defaultValue: true,
            code: 'general.active'
        },
        job: {
            label: 'Pozice',
            code: 'company.job'
        },
        unit: {
            label: 'Jednotka',
            code: 'company.unit'
        },
        obligation: {
            label: 'Úvazek',
            code: 'company.obligation'
        },
        supervisor: {
            label: 'Nadřízený',
            code: 'company.supervisor'
        }
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            data: {},
            loading: false,
            visible: false,
            options: props.options,
            order: "ASC",
            displayType: 'list',
            search: '',
            filterVisible: false,
            selected: [],
            customFilters: props.customFilters || {},
            deleting: []
        };
        this.searchInput = React.createRef();
    }

    isUnmounted = false;

    searchInput: React.RefObject<Input>

    componentDidMount() {
        document.addEventListener("click", this.checkIfClickedOutside)
        this.loadSelected().then()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.value !== this.props.value && this.props.value !== this.getCurrentValue()) {
            this.loadSelected().then()
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.checkIfClickedOutside)
        this.isUnmounted = true;
    }

    checkIfClickedOutside = (e: MouseEvent) => {
        const root = document.getElementById('root');
        if (this.assertIsNode(e.target) && !this.selectRef.current?.contains(e.target) && !this.dropdownRef.current?.contains(e.target) && root?.contains(e.target)) {
            this.state.visible && this.toggleVisible(false)

        }
    }

    assertIsNode(e: EventTarget | null): e is Node {
        return !(!e || !("nodeType" in e));
    }

    loadSelected(selected?: (string | IEmployee)[]): Promise<void> {
        return Promise.resolve()
            .then(() => new Promise<void>(resolve => this.setState({loading: true}, resolve)))
            .then(() => this.fetchSelected(selected))
            .then(values => new Promise(resolve => this.setState({
                loading: false,
                selected: values
            }, resolve)))
    }

    isEmployeeArray = (array?: (IEmployee | string)[]): array is IEmployee[] => typeof array?.[0] === 'object'

    fetchSelected(selected?: (string | IEmployee)[]) {
        const {options, value} = this.props
        const {selectOptions} = this.state
        const presenter = options.contentTypePresenter || 'default'

        if (Array.isArray(value)) {
            selected = !selected ? value : selected
        } else {
            selected = value && !selected ? [value] : selected || []
        }
        const ids: string[] = Utils.parseObjectToIdentifier(selected, 'uuid')

        if (ids && ids.length > 0) {
            if (selectOptions?.length && ids.every(id => !!selectOptions.find(s => s.value === id))) {
                return ids.map(id => selectOptions.find(s => s.value === id)!)
            }
            return this.isEmployeeArray(selected) ? Promise.resolve(selected.map(e => ({
                value: e.uuid,
                label: e._presenters?.[presenter] || ''
            }))) : EmployeesService.getInstance().choiceList(options.contentTypePresenter || 'default', {
                filters: {
                    0: {
                        type: 'in',
                        field: typeof ids[0] === "number" ? 'id' : 'uuid',
                        value: ids
                    }
                }
            }).then(response => {
                return Object.entries(response.results).map(([value, label]) => ({value, label}))
            })
        }
        return Promise.resolve([])
    }

    isMultiple() {
        return this.props.options.employeeMultiple
    }

    toggleFilterVisible = () => {
        this.setState(state => ({filterVisible: !state.filterVisible}))
    }

    onSelect = (option?: any, clear: boolean = false) => {
        const {options, value} = this.props
        const allowUnselect = options.employeeAllowUnselect === undefined ? true : options.employeeAllowUnselect
        let selected = !option ? [] : [...this.state.selected]
        let finalValue = !option ? undefined : this.getCurrentValue()

        if (this.isMultiple() && option) {
            if (!Array.isArray(finalValue)) {
                finalValue = []
            }
            let index = finalValue.indexOf(option?.value)
            if (index >= 0) {
                finalValue.splice(index, 1)
                let index2 = selected.findIndex(s => s.value === option?.value)
                selected.splice(index2, 1)
            } else {
                finalValue.push(option?.value)
                selected.push({value: option?.value, label: option.label})
            }
        } else if (option) {
            selected = finalValue === option?.value && allowUnselect ? [] : option?.label ? [{
                value: option?.value,
                label: option.label
            }] : []
            finalValue = finalValue === option?.value && allowUnselect ? undefined : option?.value
        }

        (value !== finalValue || allowUnselect) && this.setState(() => ({
            selected,
            loading: false,
        }), () => !this.state.visible && this.updateParent(clear))
    };

    onClear = () => {
        this.onSelect(undefined, true)
    }

    updateParent = (clear?: boolean) => {
        const {selectOptions} = this.state
        const current = this.getCurrentValue()
        if (Array.isArray(current) && this.isMultiple() &&
            (current?.length === 0 || !current.every(c => selectOptions?.find(s => s.value === c))) && !clear) {
            this.props.onChange?.(selectOptions?.map(s => s.value))
        } else {
            this.props.onChange?.(Array.isArray(current) && current.length === 0 ? undefined : current)
        }
        this.toggleVisible(false)
    }

    onSearch(search: string) {
        this.setState({search})
    }

    toggleVisible = (visible?: boolean) => {
        this.setState({visible: visible !== undefined ? visible : !this.state.visible}, () => {
            setTimeout(() => this.state.visible && this.searchInput.current?.focus(), 1) //have to wait
        })
    }

    setSortOrder(order: "ASC" | "DESC") {
        this.setState({order})
    }

    setDisplayType(displayType: 'list' | 'tree') {
        this.setState({displayType})
    }

    unitService = () => {
        const {options} = this.props
        const structureRoot = typeof options.employeeStructureRoot! === 'object' ? options.employeeStructureRoot! : options.employeeStructureRoot!
        return CompanyStructureService.loadAllStructureChoicesById(structureRoot, 'unit')
    }

    supervisorService = () => {
        const {options} = this.props
        const structureRoot = typeof options.employeeStructureRoot! === 'object' ? options.employeeStructureRoot! : options.employeeStructureRoot!
        return CompanyStructureService.loadAllStructureChoicesById(structureRoot, 'employee')
    }

    setFilter = (filter?: IEmployeePickerFilter) => {
        this.setState({filter})
    }

    removeFilter = (filter: IEmployeePickerFilter) => {
        const {user, update} = this.props
        if (filter.id) {
            this.setState(state => ({deleting: [...state.deleting, filter.uuid]}))
            EmployeePickerFiltersService.resourceDelete(filter.id).then(() => {
                update({
                    user: {
                        ...user,
                        employeePickerFilters: [...user.employeePickerFilters.filter(f => f.uuid !== filter.uuid)]
                    }
                })
                this.setState(state => ({deleting: [...state.deleting.filter(d => d !== filter.uuid)]}))
            })
        }
    }

    saveFilter = () => {
        const {search, selected, customFilters} = this.state
        this.setFilter({
            uuid: Utils.uuid(),
            label: '',
            searchText: search || '',
            selected: selected.map(s => s.value),
            options: customFilters,
            weight: Math.max(...(this.props.user.employeePickerFilters.map(e => e.weight)), 1) + 1
        })
    }

    updateFilter = (filter: IEmployeePickerFilter) => {
        const {user, update} = this.props
        this.formRef.current?.validateFields().then(values => {
            this.setState({saving: true})
            const exists = user.employeePickerFilters.find(f => f.label === values.label)
            const data = {
                ...exists, ...filter, ...values,
                id: exists?.id || filter.id,
                uuid: exists?.uuid || filter.uuid
            };
            (data.id ? EmployeePickerFiltersService.resourceUpdate(data.id, data) : EmployeePickerFiltersService.collectionCreate(data))
                .then(result => {
                    let filters = [...user.employeePickerFilters]
                    const index = filters.findIndex(f => f.uuid === result.uuid)
                    if (index > -1) {
                        filters[index] = result
                    } else {
                        filters.push(result)
                    }
                    update({
                        user: {...user, employeePickerFilters: [...filters]}
                    })
                    this.setState({saving: false, filter: undefined})
                })
        })
    }

    editFilter = (filter: IEmployeePickerFilter) => {
        this.setState({filter}, () => this.toggleVisible(true))
    }

    applyFilter = (filter: IEmployeePickerFilter) => {
        this.setState({search: filter.searchText, customFilters: filter.options || {}},
            () => this.loadSelected(filter.selected))
    }

    onSelectOptionsChange = (options?: ILabelValue<string>[]) => {
        this.setState({selectOptions: options})
    }

    getCurrentValue() {
        const {selected} = this.state
        return this.isMultiple() ? selected.map(s => s.value) : selected[0]?.value;
    }

    manageCustomFilter = (key: string, add = true) => {
        let customFilters = {...this.state.customFilters}
        if (add) {
            customFilters = {...customFilters, [key]: this.CUSTOM_FILTERS[key]?.defaultValue}
        } else {
            delete customFilters[key]
        }
        this.setState({customFilters})
    }

    setCustomFilter = (key: string, value: any) => {
        let customFilters = {...this.state.customFilters}
        customFilters = {...customFilters, [key]: value}
        this.setState({customFilters})
    }

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        if (oldIndex === newIndex) {
            return
        }
        const {user, update} = this.props
        const sortList = arrayMove(user.employeePickerFilters, oldIndex, newIndex)
        sortList.forEach((value, index) => {
            value.weight = index
        })
        update({
            user: {...user, employeePickerFilters: sortList}
        })
        UsersService.resourceUpdate(user.uuid, {employeePickerFilters: sortList})
    }

    render() {
        const {options, user} = this.props
        const {
            displayType,
            order,
            search,
            selected,
            visible,
            loading,
            selectOptions,
            filter,
            saving,
            deleting,
            customFilters
        } = this.state

        const {unit, supervisor, ...leftCustomFilters} = customFilters
        const allFilters = {...options.customFilters, ...AntdTableOptionsToRestOptionsService.parseFilters(leftCustomFilters)}

        let roots: string[] | undefined = [...(unit ? [unit] : []), ...(supervisor ? [supervisor] : []), ...(options.employeeStructureRoot ? [options.employeeStructureRoot] : [])]
        roots = roots.length ? roots : undefined
        const value = this.getCurrentValue()

        const treeFilters = {...options.customFilters, ...AntdTableOptionsToRestOptionsService.parseFilters(leftCustomFilters, undefined, {active: 'employee.active', obligation: 'employee.obligation', job: 'employee.job'})}

        return (
            <>
                {filter && (
                    <Modal zIndex={1900} onCancel={() => this.setFilter()} title={'Upravit'} visible={true}
                           footer={<Row justify={"end"}>
                               <Button loading={saving} type={"primary"} onClick={() => this.updateFilter(filter)}
                                       size={"small"}
                                       className={'mr-2'} icon={<SaveOutlined/>}>Uložit</Button>
                           </Row>}>
                        <Form ref={this.formRef} initialValues={filter}>
                            <Form.Item label={'Název'} name={'label'}
                                       rules={[{required: true, message: 'Název je povinný'}]}>
                                <Input/>
                            </Form.Item>
                        </Form>
                    </Modal>
                )}
                <div className={'w-100'} ref={this.selectRef}>
                    <Select className={'w-100'} placeholder={options.placeholder} allowClear={options.showClear}
                            disabled={options.disabled} loading={loading} onClear={() => this.onClear()}
                            maxTagCount={3}
                            onDeselect={(_, option) => this.onSelect(option)}
                            open={visible} mode={this.isMultiple() ? 'multiple' : undefined}
                            dropdownMatchSelectWidth={false} dropdownClassName={'pb-0'}
                            onFocus={() => !visible && this.toggleVisible()} value={value} dropdownRender={() => (
                        <div ref={this.dropdownRef}>
                            <Card title="Hledat zaměstnance" size={"small"} bordered={false} className={'m-0'}>
                                {user.employeePickerFilters.length > 0 && (
                                    <div className={'mb-2'}>
                                        <ScrollContainer orientation={"horizontal"} visibility={"auto"} style={{
                                            maxWidth: 600,
                                            overflow: "auto"
                                        }}>
                                            <DragSortList axis={"x"} className={'d-flex flex-row'} lockAxis={"x"}
                                                          onSortEnd={(sort) => this.onSortEnd(sort)} item={{
                                                render: (filter) => (
                                                    <div>
                                                        <Input.Group compact={true} className={'d-flex no-wrap'}>
                                                            <Button size={'small'}
                                                                    onClick={() => this.applyFilter(filter)}>{filter.label}</Button>
                                                            <Dropdown placement={"bottomCenter"}
                                                                      overlay={
                                                                          <Card className={'p-2'}>
                                                                              <Row gutter={[4, 4]}>
                                                                                  <Col>
                                                                                      <Button size={'small'}
                                                                                              icon={<EditOutlined/>}
                                                                                              onClick={() => this.editFilter(filter)}>
                                                                                          Přejmenovat
                                                                                      </Button>
                                                                                  </Col>
                                                                                  <Col>
                                                                                      <Button type={"danger"}
                                                                                              size={'small'}
                                                                                              icon={<DeleteOutlined/>}
                                                                                              onClick={() => this.removeFilter(filter)}
                                                                                              loading={deleting.includes(filter.uuid)}>
                                                                                          Smazat
                                                                                      </Button>
                                                                                  </Col>
                                                                              </Row>
                                                                          </Card>
                                                                      }>
                                                                <Button size={'small'}
                                                                        icon={<EllipsisOutlined rotate={90}/>}
                                                                        style={{zIndex: 1051}}
                                                                        loading={deleting.includes(filter.uuid)}/>
                                                            </Dropdown>
                                                        </Input.Group>
                                                    </div>
                                                ),
                                                className: 'mr-2',
                                                style: {zIndex: 1054}
                                            }}
                                                          children={user.employeePickerFilters.sort((a, b) => a.weight - b.weight)}/>
                                        </ScrollContainer>
                                    </div>)}
                                <Row className={'mb-2'}>
                                    <Col flex={'1 1'} className={'mr-2'}>
                                        <Input size={"small"} style={{minWidth: 200}} value={search}
                                               onChange={(event) => this.onSearch(event.target.value)}
                                               ref={this.searchInput} placeholder={'Hledat podle jména...'}/>
                                    </Col>
                                    <Dropdown placement={"bottomCenter"} trigger={['click']}
                                              overlay={
                                                  <Card className={'p-2'}>
                                                      {Object.entries(this.CUSTOM_FILTERS).map(([key, info]) => (
                                                          <div className={'mb-1'}>
                                                              <Checkbox
                                                                  checked={Object.keys(customFilters).includes(key)}
                                                                  onChange={e => this.manageCustomFilter(key, e.target.checked)}>
                                                                  <LocaleText code={info.code} fallback={info.label}/>
                                                              </Checkbox>
                                                          </div>
                                                      ))
                                                      }
                                                  </Card>
                                              }>
                                        <Button type={Object.keys(customFilters).length > 0 ? "primary" : 'default'}
                                                onClick={this.toggleFilterVisible}
                                                style={{zIndex: 1051}} shape={"circle"}
                                                size={"small"} icon={<FilterOutlined/>}/>
                                    </Dropdown>
                                </Row>
                                <div className={'mb-2'}>
                                    {Object.entries(customFilters).map(([key, value]) => (
                                        <Row gutter={[8, 8]} justify={"space-between"} align={"middle"}
                                             className={'mb-2'}>
                                            <Col>{<LocaleText code={this.CUSTOM_FILTERS[key]?.code} fallback={this.CUSTOM_FILTERS[key]?.label}/>}</Col>
                                            <Col flex={'1 1'}>
                                                <Row wrap={false} align={"middle"}>
                                                    <Col flex={'1 1'} className={'d-flex justify-content-end'}>
                                                        {{
                                                            active: <Switch checked={value}
                                                                            defaultChecked={this.CUSTOM_FILTERS[key]?.defaultValue}
                                                                            onChange={value => this.setCustomFilter(key, value)}/>,
                                                            job: <ResourcePicker value={value}
                                                                                 fetchChoices={() => JobsService.getInstance().choiceList()}
                                                                                 onChange={value => this.setCustomFilter(key, value)}/>,
                                                            unit: <ResourcePicker value={value}
                                                                                  fetchChoices={() => this.unitService()}
                                                                                  onChange={value => this.setCustomFilter(key, value)}/>,
                                                            obligation: <ResourcePicker value={value}
                                                                                        fetchChoices={() => ObligationsService.choiceList()}
                                                                                        onChange={value => this.setCustomFilter(key, value)}/>,
                                                            supervisor: <ResourcePicker value={value}
                                                                                        fetchChoices={() => this.supervisorService()}
                                                                                        onChange={value => this.setCustomFilter(key, value)}/>
                                                        }[key]}
                                                    </Col>
                                                    <Col>
                                                        <Button className={'ml-2'} size={'small'} type={"link"}
                                                                icon={<CloseOutlined/>} danger
                                                                onClick={() => this.manageCustomFilter(key, false)}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                                <div className={'p-2 mb-2'}>
                                    <Row className={"mb-2"} gutter={[6, 6]}>
                                        <Col flex={'1'}>
                                            {selectOptions?.length !== undefined && (
                                                <Row align={'middle'} className={'border rounded bg-gray-1 h-100 pl-2'}>
                                                    {selectOptions?.length} zaměstnanců
                                                </Row>
                                            )}
                                        </Col>
                                        {!options.employeeDisableTree && (
                                            <Col>
                                                <ButtonGroup className={"pr-2"}>
                                                    <Button size={'small'} icon={<BarsOutlined/>}
                                                            type={displayType === 'list' ? 'primary' : 'default'}
                                                            onClick={() => this.setDisplayType('list')}/>
                                                    <Button size={'small'} icon={<ApartmentOutlined/>}
                                                            type={displayType === 'tree' ? 'primary' : 'default'}
                                                            onClick={() => this.setDisplayType('tree')}/>
                                                </ButtonGroup>
                                            </Col>
                                        )}
                                        <Col>
                                            <ButtonGroup className={"pr-2"}>
                                                <Button size={'small'} icon={<SortAscendingOutlined/>}
                                                        type={order === 'ASC' ? 'primary' : 'default'}
                                                        onClick={() => this.setSortOrder('ASC')}/>
                                                <Button size={'small'} icon={<SortDescendingOutlined/>}
                                                        type={order === 'DESC' ? 'primary' : 'default'}
                                                        onClick={() => this.setSortOrder('DESC')}/>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <div hidden={displayType !== 'list'}>
                                        <EmployeeDisplayList
                                            maxHeight={200}
                                            onSelect={(item) => this.onSelect(item)}
                                            search={search}
                                            onOptionsChange={this.onSelectOptionsChange}
                                            value={value}
                                            order={order}
                                            onlyDirectChildren={options.employeeOnlyDirectChildren}
                                            root={roots}
                                            activeOn={options.employeeActiveOn}
                                            includeInactive={options.employeeIncludeInactive}
                                            subordinates={options.employeeSubordinates}
                                            currentEmployee={user.employees[0]}
                                            presenter={options.contentTypePresenter}
                                            filters={allFilters}
                                            hideCurrent={options.employeeHideCurrent}
                                        />
                                    </div>
                                    <div hidden={displayType !== 'tree'}>
                                        <FormFieldCompanyStructure
                                            filters={treeFilters}
                                            onChange={(node: any) => this.onSelect({
                                                value: node.employee!.uuid,
                                                label: node.employee!.fullName
                                            })} // we use onChangeNode instead
                                            options={{
                                                type: 'companyStructure',
                                                label: options.label,
                                                companyStructureAccepts: "employee",
                                                companyStructureMultiple: this.isMultiple(),
                                                companyStructureRoot: roots,
                                                companyStructureOnlyDirectChildren: options.employeeOnlyDirectChildren,
                                                companyStructureHideCurrent: options.employeeHideCurrent,
                                                companyStructureSearch: search,
                                                companyStructureOrder: order,
                                                companyReturnNode: true
                                            }}
                                            currentEmployee={user.employees[0]}
                                            value={value}
                                        />
                                    </div>
                                </div>

                                <Row justify={"space-between"}>
                                    <Col>
                                        <Button onClick={() => this.toggleVisible()} size={"small"} className={'mr-2'}>Zrušit</Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={this.saveFilter} size={"small"} className={'mr-2'}>
                                            Uložit filtr
                                        </Button>
                                        <Button
                                            type={this.isFilteredOrSelected(selected, search, customFilters) ? 'primary' : 'default'}
                                            onClick={() => this.updateParent()} size={"small"}>Vybrat</Button>
                                    </Col>
                                </Row>
                            </Card>

                        </div>
                    )}>
                        {selected.map((option, index) => (
                            <Select.Option value={option.value} key={index}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

            </>

        )
    }

    isFilteredOrSelected(selected: ILabelValue<string>[], search: string, customFilters: { [p: string]: any }) {
        return selected.length > 0 || search || (Object.values(customFilters).length > 0 && Object.values(customFilters).some(f => !!f))
    }
}

const mapStateToProps = (state: IAppState) => {
    const {user} = state.setup
    return {
        user,
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name)
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => dispatch(update(changes))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFieldEmployee)